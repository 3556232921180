import React, { useEffect, useState } from 'react'
import { ReportsTab } from '../components'
import { Grid, Paper, Button, Table, TableHead, TableBody, TableRow, TableCell, TableSortLabel, Chip } from '@material-ui/core';
import { stand_bagFilter } from './';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { ExportMenu } from '../../../shared_elements';
import { globalGetService, globalExportService } from '../../../utils/globalApiServices';
import { EmptyCollection, PageLoader } from '../../../shared_elements';
import FilterListIcon from "@material-ui/icons/FilterList"
import { dayMonthDateFormat } from "../../../constants";
import { checkApiStatus, downloadFileType } from '../../../utils';
import { STableLoader } from '../../../shared_elements/loaders';
import FilterComponent from '../../../shared_elements/components/filter_component';
import Pagination from '../../../shared_elements/components/Pagination';

function EngineStandBag() {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [pageloader, setPageloader] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [filter, setFilter] = useState({});
    const [sortInfo, setSortInfo] = useState({ sort: '', sort_by: '' });
    const [standbagList, setStandBagList] = useState([]);
    const [filterEngineTypes, setFilterEngineTypes] = useState([]);
    const [filterLessee, setFilterLessee] = useState([]);


    useEffect(() => {
        getStandBagList({ per_page: 20 })
        getLessee()
        getEngineTypes()
    }, [])

    const getStandBagList = (query, loaderType) => {
        if (loaderType === 'pageLoader') { setPageloader(true) }
        else setSkeletonLoader(true)
        globalGetService(`camo/reports/stand_bag/`, query)
            .then(response => {
                if (loaderType === 'pageLoader') { setPageloader(false) } else { setSkeletonLoader(false) }
                setStandBagList(response.data.data)
                if (query) {
                    delete query?.per_page
                    delete query?.page
                    delete query?.total
                    setFilter(query)
                }
            })
    }
    const getEngineTypes = () => {
        globalGetService(`console/engine-types/`, { distinct: 1 })
            .then(response => {
                if (checkApiStatus(response)) {
                    setFilterEngineTypes(response.data.data.engineTypes)
                }
            })
    }
    const getLessee = () => {
        globalGetService(`console/lessees/?distinct=1`)
            .then(response => {
                if (checkApiStatus(response)) {
                    setFilterLessee(response.data.data.lessees)
                }
            })
    }

    const exportserviciable = (extension) => {
        setPageloader(true)
        let queryParams = {}
        if (sortInfo?.sort === '' &&  sortInfo?.sort_by === '') {
            queryParams = Object.assign({}, filter);
        }else{
            queryParams = Object.assign({}, filter,sortInfo);
        }
        delete queryParams.per_page
        globalExportService(`camo/reports/stand_bag/?download=${extension}`, { ...queryParams })
            .then((response) => {
                setPageloader(false)
                if (response.status === 500) {
                    enqueueSnackbar("Something went wrong.", { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, });
                } else {
                    downloadFileType(response.data, `Engine_Stand_Bag_Report`, extension);
                    enqueueSnackbar("Engine Stand/Bag Report Download Successfully", { variant: "success", anchorOrigin: { vertical: "top", horizontal: "right" }, });
                }
            });
    }

    const createSortHandler = (key) => {
        let sortQuery = { sort: key };
        if (sortInfo.sort === key) {
            sortQuery = { ...sortQuery, sort_by: sortInfo.sort_by === 'asc' ? 'desc' : 'asc' }
        } else {
            sortQuery = { ...sortQuery, sort_by: 'asc' }
        }
        setSortInfo(sortQuery)
        getStandBagList({ ...filter, ...sortQuery, page: 1, per_page: standbagList.pagination.per_page }, 'pageLoader');
    }
    const tableSorting = (key, name) => {
        return <TableSortLabel active={sortInfo.sort === key ? true : false} direction={sortInfo.sort === key ? sortInfo.sort_by : 'asc'} onClick={() => createSortHandler(key)} > {name}</TableSortLabel>
    }

    const filterObj = Object.assign({}, filter, delete filter?.page, delete filter?.sort, delete filter?.sort_by,)
    let filterOptions = Object.assign({}, stand_bagFilter);
    filterOptions = {
        ...filterOptions,
        engine_type: {
            ...filterOptions.engine_type,
            options: filterEngineTypes
        },
        current_lessee: {
            ...filterOptions.current_lessee,
            options: filterLessee
        },
    }

    return (
        <section className="fleets-management">
            <ReportsTab
                tabIndex="engStandBag"
            />
            {skeletonLoader ? <STableLoader count={9} /> :
                <Paper>
                    <Grid container spacing={1}>
                        <Grid item sm={9}>
                            <FilterComponent
                                icon={<FilterListIcon style={{ margin: '5px 0px 5px 10px' }} />}
                                filter={filterObj}
                                filterMenu={filterOptions}
                                getResponseBack={(applyFilter) => { getStandBagList({ ...applyFilter, page: 1, per_page: standbagList?.pagination?.per_page }, 'pageLoader'); }}
                            />
                        </Grid>
                        <Grid md={3} style={{ margin: 'auto' }}>
                            <ul className='list-inline' style={{ float: 'right' }}>
                                <li className='list-inline-item' style={{ margin: '8px 14px' }}>
                                    <ExportMenu
                                        exportReportFn={(file) => exportserviciable(file.extension)}
                                        files={[{ title: 'EXCEL', extension: 'xls' }]}
                                        title='Export'
                                    />
                                </li>
                            </ul>
                        </Grid>
                    </Grid>
                    <div style={{ height: `${window.innerHeight - 230}px`, overflow: 'auto' }} >
                        <Table className='mui-table-format' stickyHeader={true}>
                            <TableHead>
                                <TableCell style={{ minWidth: '200px' }} >{tableSorting('update_date', 'Update Date')}</TableCell>
                                <TableCell style={{ minWidth: '200px' }}>{tableSorting('owner', 'Owner of A/C')}</TableCell>
                                <TableCell>{tableSorting('engine_type', 'Engine Type')}</TableCell>
                                <TableCell>{tableSorting('esn', 'ESN')}</TableCell>
                                <TableCell>{tableSorting('current_lessee', 'Operator(Lessee)')}</TableCell>
                                <TableCell>{tableSorting('bag_cover', 'Bag/Cover ')}</TableCell>
                                <TableCell style={{ minWidth: '200px' }} >{tableSorting('std_manufacture', 'Stand Manufacture')}</TableCell>
                                <TableCell style={{ minWidth: '150px' }}>{tableSorting('engine_stand_color', 'Engine Stand Color')}</TableCell>
                                <TableCell style={{ minWidth: '200px' }} >{tableSorting('engine_stand_part_number', 'Engine Stand Prime P/N')}</TableCell>
                                <TableCell style={{ minWidth: '200px' }} >{tableSorting('engine_stand_serial_number', 'Engine Stand Prime S/N')}</TableCell>
                                <TableCell style={{ minWidth: '200px' }}>{tableSorting('engine_stand_base_pn', 'Engine Stand Base P/N')}</TableCell>
                                <TableCell style={{ minWidth: '200px' }}>{tableSorting('engine_stand_base_sn', 'Engine Stand Base S/N')}</TableCell>
                                <TableCell style={{ minWidth: '200px' }}>{tableSorting('engine_stand_cradle_pn', 'Engine Stand Cradle P/N')}</TableCell>
                                <TableCell style={{ minWidth: '200px' }}>{tableSorting('engine_stand_cradle_sn', 'Engine Stand Cradle S/N')}</TableCell>
                                <TableCell style={{ minWidth: '200px' }} >{tableSorting('current_loction', 'Location (Country)')}</TableCell>
                                <TableCell>{tableSorting('manufacturing_date', 'Mfg Date')}</TableCell>
                                <TableCell style={{ minWidth: '200px' }} >{tableSorting('service_date', 'Service Date')}</TableCell>
                                <TableCell style={{ minWidth: '200px' }} >{tableSorting('shock_mount_mfg_date', 'Shock Mount Mfg Date')}</TableCell>
                                <TableCell style={{ minWidth: '200px' }} >{tableSorting('shock_mount_limit', 'Shock Mount Limit (Years)')}</TableCell>
                                <TableCell style={{ minWidth: '200px' }} >{tableSorting('shock_mount_exp_date', 'Shock Mount Exp Date')}</TableCell>
                                <TableCell>{tableSorting('comments', 'Comments')}</TableCell>
                                <TableCell style={{ minWidth: '200px' }} >{tableSorting('boot_strape_pn', 'Boot Strap P/N')}</TableCell>
                                <TableCell>{tableSorting('remarks', 'Remarks')}</TableCell>
                            </TableHead>
                            <TableBody>
                                {
                                    standbagList?.results?.length > 0 && standbagList?.results?.map((item, index) =>
                                        <StandBagList
                                            item={item}
                                        />
                                    )
                                }
                            </TableBody>
                        </Table>
                        {
                            standbagList?.results < 1 && !pageloader ?
                                <EmptyCollection title={'No records found'} /> : null
                        }
                    </div>
                    <Pagination
                        pagination={standbagList?.pagination}
                        onChangePage={(event, newPage) => getStandBagList({...filter, page: newPage + 1, per_page: standbagList?.pagination?.per_page }, 'pageLoader')}
                        onChangeRowsPerPage={(event) => getStandBagList({...filter, page: 1, per_page: event.target.value }, 'pageLoader')}
                    />
                </Paper>}
            {
                pageloader ? <PageLoader /> : null
            }
        </section>
    )
}
const StandBagList = ({ item }) => {
    return (
        <TableRow>
            <TableCell> {item?.update_date ? moment(item.update_date).format(dayMonthDateFormat) : '--'} </TableCell>
            <TableCell> {item?.owner || '--'} </TableCell>
            <TableCell> {item?.engine_type || '--'} </TableCell>
            <TableCell> {item?.esn || '--'} </TableCell>
            <TableCell> {item?.current_lessee || '--'} </TableCell>
            <TableCell> {item?.bag_cover || '--'} </TableCell>
            <TableCell> {item?.std_manufacture || '--'} </TableCell>
            <TableCell> {item?.engine_stand_color || '--'} </TableCell>
            <TableCell> {item?.engine_stand_part_number}  </TableCell>
            <TableCell> {item?.engine_stand_serial_number} </TableCell>
            <TableCell> {item?.engine_stand_base_pn || '--'} </TableCell>
            <TableCell> {item?.engine_stand_base_sn || '--'} </TableCell>
            <TableCell> {item?.engine_stand_cradle_pn || '--'} </TableCell>
            <TableCell> {item?.engine_stand_cradle_sn || '--'} </TableCell>
            <TableCell> {item?.current_loction || '--'} </TableCell>
            <TableCell> {item?.manufacturing_date ? moment(item.manufacturing_date).format(dayMonthDateFormat) : '--'} </TableCell>
            <TableCell> {item?.service_date ? moment(item.service_date).format(dayMonthDateFormat) : '--'} </TableCell>
            <TableCell> {item?.shock_mount_mfg_date ? moment(item.shock_mount_mfg_date).format(dayMonthDateFormat) : '--'} </TableCell>
            <TableCell> {item?.shock_mount_limit || '--'} </TableCell>
            <TableCell> {item?.shock_mount_exp_date ? moment(item.shock_mount_exp_date).format(dayMonthDateFormat) : '--'} </TableCell>
            <TableCell> {item?.comments || '--'} </TableCell>
            <TableCell> {item?.boot_strape_pn || '--'} </TableCell>
            <TableCell> {item?.remarks || '--'} </TableCell>
        </TableRow>
    )
}

export default EngineStandBag